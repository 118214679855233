<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">คลังยาและเวชภัณฑ์</h4>
      </div>
      <v-card-title class="py-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="2">
            <v-text-field
              v-model="filter_search"
              label="พิมพ์คำค้นหา"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <div class="d-flex flex-row">
              <!-- <span class="label-search-left">หมวดหมู่</span> -->
              <v-select
                :items="itemsTypeFilter"
                v-model="select_filter_type"
                item-text="name"
                item-value="name"
                label="เลืือกหมวดหมู่"
                single-line
                hide-details
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <div class="d-flex flex-row">
              <v-select
                :items="itemsGroupFilter"
                v-model="select_filter_group"
                item-text="name"
                item-value="name"
                label="เลืือกกลุ่ม"
                single-line
                hide-details
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="3" class="d-flex flex-row align-center">
            <v-menu
              ref="menuDataDialogDate"
              v-model="menuDataDialogDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedFilterDatesFormatted"
                  label="วัน/เดือน/ปี"
                  append-outer-icon="mdi-calendar-month"
                  readonly
                  required
                  @click:append-outer="menuDataDialogDate = true"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                range
                no-title
                @change="menuDataDialogDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer/>
          <div class="d-flex align-center justify-end px-3 pb-3">
            <v-btn
              :loading="loaddata"
              :disabled="loaddata"
              color="blue-grey"
              class="white--text"
              @click="searchData"
            >
              <v-icon
                left
                dark
              >
                mdi-magnify
              </v-icon>
              ค้นหาข้อมูลการตัดสต๊อก
            </v-btn>
          </div>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="filter_search"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
        :footer-props="{
          'items-per-page-text':'การแสดงข้อมูล'
        }"
      >
      <template v-slot:top>
        <div class="d-flex flex-row">
          <v-spacer/>
          <v-btn
            :loading="loader"
            :disabled="loader"
            color="secondary"
            class="my-3 white--text"
            @click="goOverview"
          >
            <v-icon
              left
              dark
            >
              mdi-file-pdf
            </v-icon>
            แสดงข้อมูลภาพรวม
          </v-btn>
        </div>
      </template>
      <template v-slot:item.product_category="{ item }">
        <div v-if="item.product_category === 'ยา'" class="d-flex flex-row">
          <v-icon class="mr-2">mdi-pill</v-icon>
          <span>ยา</span>
        </div>
        <div v-else class="d-flex flex-row">
          <v-icon class="mr-2">mdi-needle</v-icon>
          <span>เวชภัณฑ์</span>
        </div>
      </template>
      <template v-slot:item.product_name="{ item }">
        <div class="text-start">{{item.product_name}}</div>
      </template>
      <template v-slot:item.product_costprice="{ item }">
        <div class="text-end">{{formatNumber(item.product_costprice)}}</div>
      </template>
      <template v-slot:item.product_sellprice="{ item }">
        <div class="text-end">{{formatNumber(item.product_sellprice) === '0.00' ? '-' : formatNumber(item.product_sellprice)}}</div>
      </template>
      <template v-slot:item.product_used="{ item }">
        <div class="text-end">{{formatNumber(item.product_used)}}</div>
      </template>
      <template v-slot:item.product_remain="{ item }">
        <div class="d-flex flex-row align-center">
          <span>{{formatNumber(item.product_remain)}}</span>
          <div
            v-if="item.product_remain === 0"
            class="d-flex flex-column align-center justify-center btn-warning-outstock px-3 py-1 ml-3"
          >
            <v-icon color="#FFF" size="18">mdi-alert-circle</v-icon>
            <span class="label-warning-outstock">หมดแล้ว</span>
          </div>
          <div
            v-else-if="item.product_remain <= item.product_remain_alert"
            class="d-flex flex-column align-center justify-center btn-warning-nearoutstock px-3 py-1 ml-3"
          >
            <v-icon color="#FFC107" size="18">mdi-alert-circle</v-icon>
            <span class="label-warning-nearoutstock">ใกล้หมด</span>
          </div>
        </div>
      </template>
      <template v-slot:item.product_usedate="{ item }">
        {{computedUsedDates(item.product_usedate)}}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          @click="viewItem(item)"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="primary lighten-1 white--text">
          ข้อมูลการตัดสต๊อก
        </v-card-title>

        <v-card-text class="pt-3">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="3">
              <span class="label-drudsandmedical-add-stock-incard">รหัส:</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="9">
              <span>{{dataDialog.product_code}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="3">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="9">
              <span>{{dataDialog.product_name}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="3">
              <span class="label-drudsandmedical-add-stock-incard">ใช้ไป:</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="9">
              <span>{{dataDialog.product_used}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="3">
              <span class="label-drudsandmedical-add-stock-incard">หัตถการ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="9">
              <span>TEST</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeDialog"
          >
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      cansave: true,
      filter_search: '',
      select_filter_type: '',
      select_filter_group: '',
      dates: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
      select_filter_dates: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
      showDialog: false,
      dataDialog: {},
      boolAddItems: false,
      boolViewItems: false,
      boolEditItems: false,
      boolDelItems: false,
      itemsTypeFilter: [],
      itemsGroupFilter: [],
      itemsProductTypeCreate: [],
      data: [],
      menuFilterDateStart: false,
      menuFilterDateEnd: false,
      menuDataDialogDate: false,
      menuDataDialogDateStart: false,
      menuDataDialogDateEnd: false
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '#',
          align: 'center',
          sortable: false,
          value: 'product_no'
        }, {
          text: 'หมวดหมู่',
          align: 'center',
          value: 'product_category',
          sortable: false
        }, {
          text: 'รหัส',
          align: 'center',
          value: 'product_code',
          sortable: false
        }, {
          text: 'ชื่อ',
          align: 'center',
          value: 'product_name',
          sortable: false
        }, {
          text: 'กลุ่ม',
          align: 'center',
          sortable: false,
          value: 'product_group_name'
        }, {
          text: 'ประเภท',
          align: 'center',
          value: 'product_type',
          sortable: false
        }, {
          text: 'ราคาทุน',
          align: 'center',
          value: 'product_costprice',
          sortable: false
        }, {
          text: 'ราคาขาย',
          align: 'center',
          value: 'product_sellprice',
          sortable: false
        }, {
          text: 'ใช้ไป',
          align: 'center',
          value: 'product_used',
          sortable: false
        }, {
          text: 'คงเหลือปัจจุบัน',
          align: 'center',
          value: 'product_remain',
          sortable: false
        }, {
          text: 'หน่วยนับ',
          align: 'center',
          value: 'product_unit',
          sortable: false
        }, {
          text: 'วันที่ใช้',
          align: 'center',
          value: 'product_usedate',
          sortable: false
        }, {
          text: 'ชื่อผู้ทำรายการ',
          align: 'center',
          value: 'product_createby',
          sortable: false
        }, {
          text: 'ข้อมูลการตัดสต๊อก',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    computedFilterDatesFormatted () {
      const self = this
      return `${self.dates[0] ? moment(self.dates[0]).add(543, 'year').format('DD/MM/YYYY') : moment().add(543, 'year').format('DD/MM/YYYY')} ถึง ${self.dates[1] ? moment(self.dates[1]).add(543, 'year').format('DD/MM/YYYY') : moment(self.dates[0]).add(543, 'year').format('DD/MM/YYYY')}`
    }
  },
  watch: {
    select_filter_search () {
      const self = this
      if (self.select_filter_search === 'ทั้งหมด') {
        self.filter_search = ''
      } else {
        self.filter_search = self.select_filter_search
      }
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const self = this
      self.data = []
      self.fetchCategory()
    },
    async fetchCategory () {
      const self = this
      self.fetchData()
    },
    async fetchData () {
      const self = this
      self.loaddata = true
      var temp = []
      temp = [{
        _id: '000000',
        product_no: 1,
        product_category: 'ยา',
        product_code: 'M100001',
        product_name: 'Botox กราม (0.7)',
        product_group_name: 'Botox',
        product_type: 'หน้า',
        product_costprice: 1500,
        product_sellprice: 2999,
        product_used: 50,
        product_remain: 50,
        product_remain_alert: 50,
        product_unit: 'คู่',
        product_usedate: [moment(), moment()],
        product_createby: 'Admin_WG001'
      }, {
        _id: '000001',
        product_no: 2,
        product_category: 'ยา',
        product_code: 'M100002',
        product_name: 'Botox Allergan (ขวด)',
        product_group_name: 'Botox',
        product_type: 'กราม',
        product_costprice: 1500,
        product_sellprice: 2999,
        product_used: 0,
        product_remain: 0,
        product_remain_alert: 50,
        product_unit: 'ขวด',
        product_usedate: [moment(), moment()],
        product_createby: 'Admin_WG001'
      }, {
        _id: '000002',
        product_no: 3,
        product_category: 'ยา',
        product_code: 'M100003',
        product_name: 'Botox กราม (0.7)',
        product_group_name: 'Botox',
        product_type: 'หน้า',
        product_costprice: 800,
        product_sellprice: 1999,
        product_used: 200,
        product_remain: 200,
        product_remain_alert: 50,
        product_unit: 'CC',
        product_usedate: [moment(), moment()],
        product_createby: 'Admin_WG001'
      }, {
        _id: '000003',
        product_no: 4,
        product_category: 'ยา',
        product_code: 'M100004',
        product_name: 'Botox กราม (0.7)',
        product_group_name: 'Botox',
        product_type: 'หน้า',
        product_costprice: 700,
        product_sellprice: 1999,
        product_used: 166,
        product_remain: 166,
        product_remain_alert: 50,
        product_unit: 'เส้น',
        product_usedate: [moment(), moment()],
        product_createby: 'Admin_WG001'
      }, {
        _id: '000004',
        product_no: 5,
        product_category: 'ยา',
        product_code: 'M100005',
        product_name: 'Filler (cc)',
        product_group_name: 'Filler',
        product_type: 'หน้า',
        product_costprice: 2500,
        product_sellprice: 4500,
        product_used: 90,
        product_remain: 90,
        product_remain_alert: 50,
        product_unit: 'CC',
        product_usedate: [moment(), moment()],
        product_createby: 'Admin_WG001'
      }, {
        _id: '000005',
        product_no: 6,
        product_category: 'เวชภัณฑ์',
        product_code: 'E100001',
        product_name: 'Tube PRP (หลอด)',
        product_group_name: 'PRP',
        product_type: 'PRP หน้า',
        product_costprice: 20,
        product_sellprice: 0,
        product_used: 2,
        product_remain: 400,
        product_remain_alert: 100,
        product_unit: 'หลอด',
        product_usedate: [moment(), moment()],
        product_createby: 'Admin_WG001'
      }]
      setTimeout(() => {
        self.data = temp
        self.loaddata = false
      }, 200)
    },
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    searchData () {
      const self = this
      self.initData()
    },
    computedUsedDates (items) {
      return `${moment(items[0]).add(543, 'year').format('DD/MM/YYYY')} ถึง ${moment(items[1]).add(543, 'year').format('DD/MM/YYYY')}`
    },
    formatNumber (num) {
      var p = num.toFixed(2).split('.')
      return p[0].split('').reverse().reduce((acc, num, i, orig) => {
        return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc
      }, '') + '.' + p[1]
    },
    viewItem (item) {
      const self = this
      self.showDialog = true
      self.dataDialog = item
    },
    closeDialog () {
      const self = this
      self.showDialog = false
      self.dataDialog = {}
    },
    goOverview () {
      const self = this
      self.loaddata = true
      setTimeout(() => {
        self.loaddata = false
        self.$router.push({ name: 'OverviewDrugAndMedicalWarehouseScreen' })
      }, 200)
    }
  }
}
</script>
