var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[_c('v-card',{staticClass:"pa-5",attrs:{"elevation":"0"}},[_c('div',{staticClass:"page-title-box d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0"},[_vm._v("คลังยาและเวชภัณฑ์")])]),_c('v-card-title',{staticClass:"py-0 px-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"2"}},[_c('v-text-field',{attrs:{"label":"พิมพ์คำค้นหา","single-line":"","hide-details":""},model:{value:(_vm.filter_search),callback:function ($$v) {_vm.filter_search=$$v},expression:"filter_search"}},[_c('v-icon',{attrs:{"slot":"prepend","color":"#182839"},slot:"prepend"},[_vm._v(" mdi-magnify ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"2"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-select',{attrs:{"items":_vm.itemsTypeFilter,"item-text":"name","item-value":"name","label":"เลืือกหมวดหมู่","single-line":"","hide-details":""},model:{value:(_vm.select_filter_type),callback:function ($$v) {_vm.select_filter_type=$$v},expression:"select_filter_type"}})],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"2"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-select',{attrs:{"items":_vm.itemsGroupFilter,"item-text":"name","item-value":"name","label":"เลืือกกลุ่ม","single-line":"","hide-details":""},model:{value:(_vm.select_filter_group),callback:function ($$v) {_vm.select_filter_group=$$v},expression:"select_filter_group"}})],1)]),_c('v-col',{staticClass:"d-flex flex-row align-center",attrs:{"cols":"12","sm":"12","md":"12","lg":"3"}},[_c('v-menu',{ref:"menuDataDialogDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedFilterDatesFormatted,"label":"วัน/เดือน/ปี","append-outer-icon":"mdi-calendar-month","readonly":"","required":""},on:{"click:append-outer":function($event){_vm.menuDataDialogDate = true}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDataDialogDate),callback:function ($$v) {_vm.menuDataDialogDate=$$v},expression:"menuDataDialogDate"}},[_c('v-date-picker',{attrs:{"range":"","no-title":""},on:{"change":function($event){_vm.menuDataDialogDate = false}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center justify-end px-3 pb-3"},[_c('v-btn',{staticClass:"white--text",attrs:{"loading":_vm.loaddata,"disabled":_vm.loaddata,"color":"blue-grey"},on:{"click":_vm.searchData}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" ค้นหาข้อมูลการตัดสต๊อก ")],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.filter_search,"loading":_vm.loaddata,"loading-text":"กำลังโหลด...","footer-props":{
        'items-per-page-text':'การแสดงข้อมูล'
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-row"},[_c('v-spacer'),_c('v-btn',{staticClass:"my-3 white--text",attrs:{"loading":_vm.loader,"disabled":_vm.loader,"color":"secondary"},on:{"click":_vm.goOverview}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-file-pdf ")]),_vm._v(" แสดงข้อมูลภาพรวม ")],1)],1)]},proxy:true},{key:"item.product_category",fn:function(ref){
      var item = ref.item;
return [(item.product_category === 'ยา')?_c('div',{staticClass:"d-flex flex-row"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-pill")]),_c('span',[_vm._v("ยา")])],1):_c('div',{staticClass:"d-flex flex-row"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-needle")]),_c('span',[_vm._v("เวชภัณฑ์")])],1)]}},{key:"item.product_name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-start"},[_vm._v(_vm._s(item.product_name))])]}},{key:"item.product_costprice",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.formatNumber(item.product_costprice)))])]}},{key:"item.product_sellprice",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.formatNumber(item.product_sellprice) === '0.00' ? '-' : _vm.formatNumber(item.product_sellprice)))])]}},{key:"item.product_used",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.formatNumber(item.product_used)))])]}},{key:"item.product_remain",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center"},[_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.product_remain)))]),(item.product_remain === 0)?_c('div',{staticClass:"d-flex flex-column align-center justify-center btn-warning-outstock px-3 py-1 ml-3"},[_c('v-icon',{attrs:{"color":"#FFF","size":"18"}},[_vm._v("mdi-alert-circle")]),_c('span',{staticClass:"label-warning-outstock"},[_vm._v("หมดแล้ว")])],1):(item.product_remain <= item.product_remain_alert)?_c('div',{staticClass:"d-flex flex-column align-center justify-center btn-warning-nearoutstock px-3 py-1 ml-3"},[_c('v-icon',{attrs:{"color":"#FFC107","size":"18"}},[_vm._v("mdi-alert-circle")]),_c('span',{staticClass:"label-warning-nearoutstock"},[_vm._v("ใกล้หมด")])],1):_vm._e()])]}},{key:"item.product_usedate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.computedUsedDates(item.product_usedate))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initData}},[_vm._v(" รีเซ็ต ")])]},proxy:true}])})],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary lighten-1 white--text"},[_vm._v(" ข้อมูลการตัดสต๊อก ")]),_c('v-card-text',{staticClass:"pt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"3"}},[_c('span',{staticClass:"label-drudsandmedical-add-stock-incard"},[_vm._v("รหัส:")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"9"}},[_c('span',[_vm._v(_vm._s(_vm.dataDialog.product_code))])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"3"}},[_c('span',{staticClass:"label-drudsandmedical-add-stock-incard"},[_vm._v("ชื่อ:")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"9"}},[_c('span',[_vm._v(_vm._s(_vm.dataDialog.product_name))])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"3"}},[_c('span',{staticClass:"label-drudsandmedical-add-stock-incard"},[_vm._v("ใช้ไป:")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"9"}},[_c('span',[_vm._v(_vm._s(_vm.dataDialog.product_used))])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"3"}},[_c('span',{staticClass:"label-drudsandmedical-add-stock-incard"},[_vm._v("หัตถการ:")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"9"}},[_c('span',[_vm._v("TEST")])])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" ปิด ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }